import _ from "lodash";

const savedFormValuesReducer = (state, action) => {
  switch (action.type) {
    case "SET_ENTIRE_STATE":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_TRUV_DATA":
      // when a customer first logs in to AN3 and add a new employer the payload is an object
      // handle below condition to convert object to array
      return {
        ...state,
        truvData: Array.isArray(action.payload)
          ? action.payload
          : [action.payload],
      };
    case "UPDATE_TRUV_DATA":
      const existingIdx = state.truvData?.findIndex(
        (data) => data.id === action.payload?.id
      );
      const newTruvData = [...state?.truvData];
      if (existingIdx > -1) {
        newTruvData[existingIdx] = action.payload;
      } else {
        newTruvData.push(action.payload);
      }

      return {
        ...state,
        truvData: newTruvData,
      };
    case "MASTER_UPDATE":
      let localCopy = _.cloneDeep(state);
      _.set(localCopy, action.payload.dataMap, action.payload.value);

      return localCopy;
    case "MASTER_UPDATE_MULTI":
      let localCopyMulti = _.cloneDeep(state);
      action.payload.map((i) => {
        _.set(localCopyMulti, i.dataMap, i.value);
      });
      return localCopyMulti;
    case "MASTER_UNSET":
      let unsetCopy = _.cloneDeep(state);
      _.unset(unsetCopy, action.payload.dataMap);
      return unsetCopy;
    case "UPDATE_REAL_ESTATE_LOAN":
      let updateRealEstateLoan = state.realEstate.map((i, j) => {
        if (j === action.payload.realEstateIndex) {
          if (!i.propertyLoans || i.propertyLoans.length === 0) {
            i.propertyLoans = [{}];
          }
          i.propertyLoans = i.propertyLoans.map((k, l) => {
            if (l === action.payload.loanIndex) {
              k[action.payload.dataMap] = action.payload.value;
            }
            return k;
          });
        }
        return i;
      });
      return {
        ...state,
        realEstate: updateRealEstateLoan,
      };
    case "UPDATE_ALL_REALESTATE":
      return {
        ...state,
        realEstate: action.payload,
      };
    case "UPDATE_SINGLE_REALESTATE":
      let savedRealEstate = _.cloneDeep(state.realEstate);
      savedRealEstate[action.index] = {
        ...savedRealEstate[action.index],
        ...action.payload,
      };
      return {
        ...state,
        realEstate: savedRealEstate,
      };
    case "ADD_ACCOUNT_ASSET":
      let localCopyAccountAssets = _.cloneDeep(state);
      let accountAssetIndex = 0;
      if (
        localCopyAccountAssets.assets &&
        localCopyAccountAssets.assets.accounts
      ) {
        accountAssetIndex = localCopyAccountAssets.assets.accounts.length;
      }

      _.set(
        localCopyAccountAssets,
        `assets.accounts[${accountAssetIndex}]`,
        action.payload
      );
      return localCopyAccountAssets;
    case "ADD_OTHER_ASSET":
      let localCopyOtherAssets = _.cloneDeep(state);
      let otherAssetIndex = 0;
      if (localCopyOtherAssets.assets && localCopyOtherAssets.assets.other) {
        otherAssetIndex = localCopyOtherAssets.assets.other.length;
      }

      _.set(
        localCopyOtherAssets,
        `assets.other[${otherAssetIndex}]`,
        action.payload
      );
      return localCopyOtherAssets;
    case "DELETE_ACCOUNT_ASSET":
      let deleteAccount = _.cloneDeep(state);

      let deleteAccountAsset = deleteAccount.assets.accounts.filter((i, j) => {
        if (i.key !== action.payload) {
          return i;
        }
      });
      _.set(deleteAccount, "assets.accounts", deleteAccountAsset);

      return deleteAccount;
    case "DELETE_OTHER_ASSET":
      let deleteOther = _.cloneDeep(state);
      let deleteOtherAsset = deleteOther.assets.other.filter((i, j) => {
        if (i.key !== action.payload) {
          return i;
        }
      });
      _.set(deleteOther, "assets.other", deleteOtherAsset);
      return deleteOther;
    case "ADD_ACCOUNT_LIABILITY":
      let localCopyAccountLiability = _.cloneDeep(state);
      let accountLiabilityIndex = 0;
      if (
        localCopyAccountLiability.liabilities &&
        localCopyAccountLiability.liabilities.accounts
      ) {
        accountLiabilityIndex =
          localCopyAccountLiability.liabilities.accounts.length;
      }
      _.set(
        localCopyAccountLiability,
        `liabilities.accounts[${accountLiabilityIndex}]`,
        action.payload
      );
      return localCopyAccountLiability;
    case "ADD_OTHER_LIABILITY":
      let localCopyOtherLiability = _.cloneDeep(state);
      let otherLiabilityIndex = 0;
      if (
        localCopyOtherLiability.liabilities &&
        localCopyOtherLiability.liabilities.other
      ) {
        otherLiabilityIndex = localCopyOtherLiability.liabilities.other.length;
      }

      _.set(
        localCopyOtherLiability,
        `liabilities.other[${otherLiabilityIndex}]`,
        action.payload
      );
      return localCopyOtherLiability;
    case "DELETE_ACCOUNT_LIABILITY":
      let deleteAccountLiability = _.cloneDeep(state);

      let deleteAccountItem =
        deleteAccountLiability.liabilities.accounts.filter((i, j) => {
          if (i.key !== action.payload) {
            return i;
          }
        });
      _.set(deleteAccountLiability, "liabilities.accounts", deleteAccountItem);

      return deleteAccountLiability;
    case "DELETE_OTHER_LIABILITY":
      let deleteOtherLiability = _.cloneDeep(state);
      let deleteOtherItem = deleteOtherLiability.liabilities.other.filter(
        (i, j) => {
          if (i.key !== action.payload) {
            return i;
          }
        }
      );
      _.set(deleteOtherLiability, "liabilities.other", deleteOtherItem);
      return deleteOtherLiability;
    case "DELETE_CO_BORROWER":
      let deleteCoBorrowerCopy = _.cloneDeep(state);
      deleteCoBorrowerCopy.coborrowers = [];
      return deleteCoBorrowerCopy;
    // case 'CO_PILOT_ADD_REPLACE':
    //     let addedState = _.cloneDeep( state )
    //     _.set( addedState, action.payload.dataMap, action.payload.value )
    //     return addedState
    // case 'CO_PILOT_REMOVE':
    //     let removeState = _.cloneDeep(state)
    //     _.unset( removeState, action.payload )
    //     return removeState
    case "CO_PILOT_PATCH":
      let patchCopy = _.cloneDeep(state);
      action.payload.map((patch) => {
        if (patch.op === "add") {
          let fixedAdd = patch.path
            .replace(/\//g, ".")
            .replace(/\d/g, (match) => `[${match}]`)
            .replace(/\.\[/g, "[")
            .replace(".-", "")
            .substring(1);
          if (patch.path.endsWith("/-")) {
            //adding to an array
            let currentSavedList = _.get(state, fixedAdd);
            if (currentSavedList) {
              fixedAdd += `[${currentSavedList.length}]`;
            } else {
              fixedAdd += "[0]";
            }
          }
          _.set(patchCopy, fixedAdd, patch.value);
          return;
        }
        if (patch.op === "replace") {
          let fixedReplace = patch.path
            .replace(/\//g, ".")
            .replace(/\d/g, (match) => `[${match}]`)
            .replace(/\.\[/g, "[")
            .substring(1);
          _.set(patchCopy, fixedReplace, patch.value);
          return;
        }
        if (patch.op === "remove") {
          let fixedRemove = patch.path
            .replace(/\//g, ".")
            .replace(/\d/g, (match) => `[${match}]`)
            .replace(/\.\[/g, "[")
            .substring(1);
          let re = new RegExp(/\[\d\]$/);
          if (fixedRemove.endsWith("[0]")) {
            //removing last item in array
            fixedRemove = fixedRemove.slice(0, -3);
            _.set(patchCopy, fixedRemove, []);
            return;
          } else if (re.test(fixedRemove)) {
            // removing something from an array
            let removedIndexStr = fixedRemove.slice(0, -3);
            let indexToRemove = fixedRemove.slice(-2, -1);
            let savedArray = _.get(patchCopy, removedIndexStr);
            console.log(patch);
            console.log(fixedRemove);
            console.log(removedIndexStr);
            let filteredArray = savedArray.filter((j, index) => {
              if (index != indexToRemove) {
                return j;
              }
            });
            _.set(patchCopy, removedIndexStr, filteredArray);
            return;
          } else {
            _.unset(patchCopy, fixedRemove);
          }
        }
      });
      return patchCopy;
    default:
      return state;
  }
};

export default savedFormValuesReducer;
